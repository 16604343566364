<template>
  <default-layout>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat>
          <v-toolbar-title>A/R Downpayment Invoice</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="accent" small to="/trading/new-ar-downpayment-invoice">
            <v-icon left dark>mdi-plus</v-icon>New A/R Down Payment Invoice
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="search" append-icon="mdi-search" label="Search" single-line></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="dpymtInvoices"
                :search="search"
              >
              <!-- start of posting date template -->
              <template v-slot:item.DocDate="{ item }">
                {{ item.DocDate | moment("Do MMM YYYY")}}
              </template>
              <!-- end of posting date template -->

              <!-- start of delivery date template -->
              <template v-slot:item.DocDueDate="{ item }">
                {{ item.DocDueDate | moment("Do MMM YYYY")}}
              </template>
              <!-- end of delivery date template -->

              <!-- start of document date template -->
              <template v-slot:item.TaxDate="{ item }">
                {{ item.TaxDate | moment("Do MMM YYYY")}}
              </template>
              <!-- end of document date template -->

              <!-- start of vat sum template -->
              <template v-slot:item.VatSum="{ item }">
                {{ item.VatSum.toLocaleString()}}
              </template>
              <!-- end of vat sum template -->

              <!-- start of doc total template -->
              <template v-slot:item.DocTotal="{ item }">
                {{ item.DocTotal.toLocaleString()}}
              </template>
              <!-- end of doc total template -->

              <!-- action template -->
              <template v-slot:item.action="{ item }">
                <v-btn icon :to="`/ar-invoice/${item.id}`">
                  <v-icon color="green">mdi-eye</v-icon>
                </v-btn>
              </template> 
              <!-- end of action template -->
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </default-layout>
</template>

<script>
export default {
  data: () => ({
    search: "",
    dpymtInvoices: [],
    headers: [
      { text: "Customer Code", value: "CardCode" },
      { text: "Customer Name", value: "CardName" },
      { text: "Posting Date", value: "DocDate" },
      { text: "Due Date", value: "DocDueDate" },
      { text: "Document Date", value: "TaxDate" },
      { text: "VAT", value: "VatSum" },
      { text: "Document Total", value: "DocTotal" },
      { text: "View More", value: "action" },
    ]
  }),
  methods: {
    getDpymtInvoices() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/ar_invoice`)
        .then(res => {
          console.log(res, 'r')
          // self.arInvoices = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this. getDpymtInvoices();
  }
};
</script>